import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '../Component/Alert';
import FlipImage from '../Component/FlipImage'; 
import { Box } from '@material-ui/core';
import oneside from '../Assets/BedilVol3.jpg';
import twoside from '../Assets/BedilVol32.jpg';
import Hidden from '@material-ui/core/Hidden';
import BuyOptionMenu from '../Component/BuyOptionMenu3';
import myPicture from '../Assets/desc.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '0.2em 1em',
    background: '#fff'
  },
  paper: {
    padding: theme.spacing(12),
    textAlign: 'justify',
  },
  h1:{
    fontFamily: 'titr',
    padding: '0.2em 2em',
    textAlign: 'center',
    fontSize: '2.6em',
    color: '#112D4E'
  },
  h11:{
    fontFamily: 'titr',
    padding: '0.2em 2em',
    textAlign: 'center',
    fontSize: '2.3em',
    color: '#112D4E'
  },
  para: {
    fontFamily: 'vazir',
    lineHeight: '2.4em',
    textAlign: 'justify',
    fontSize: '1.5em',
    fontWeight: 300,
    marginTop: '1em'
  },
  paratitle: {
    fontFamily: 'vazir',
    lineHeight: '2.4em',
    textAlign: 'center',
    fontSize: '1.5em',
    fontWeight: 300,
    marginTop: '1em'
  },
  para1: {
    fontFamily: 'vazir',
    lineHeight: '2.4em',
    textAlign: 'center',
    fontSize: '1.5em',
    fontWeight: 8500,
    marginTop: '1em',
    color: '#3F72AF'
  },
  gridSpace:{
    display: 'flex',
    justifyContent: 'center'
  },
  containerPic:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  picRef:{
    width: '80%', // You can adjust this value as needed to make it adaptive
    maxWidth: '1200px', // Set a maximum width to limit the picture's size on larger screens
    marginTop: '20px', // Adjust this value to set the distance between header and picture
  }
}));

export default function CenteredGrid() {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.h1}>انسان‌شناختی بیدل مجلد سوم (بخش چهارم)</h1>
      <div className={classes.root}>
      <Alert/>
      <div>
      <h1 className={classes.h11}>مژده</h1>
        <p className={classes.paratitle}>
          به‌دوست‌داران دانش، بینش و روشِ عرفان و فلسفۀ بیدل، فیلسوف‌ انسان‌گرا، نقّاد
          و روشن‌اندیش
          <br/>
          و
          <br/>
          شاعر سخن‌سنج و نکته‌پرداز دری‌زبان هندوستان!
          <br/>
          </p>
          <div className={classes.containerPic}>
            <img src={myPicture} alt="My Picture" className={classes.picRef} />
          </div>
          <p className={classes.para}>
          <br/>
          انتشارات انسان با شادمانی پرافتخار به‌پیش‌گاه دانش‌جویان، دانش‌پژوهان و
          فرهنگیان کشورهای هم‌زبان بیدل (افغانستان، ایران، تاجیکستان) پارسی‌دانان دیگر
          کشورهای جهان به‌اطلاع می‌رساند که مجلد سوم انسان‌شناختی بیدل (بخش چهارم:
          شناخت‌شناختی و روش‌شناختی بیدل) پژوهش استاد دکتر سید نورالحق کاوش با ویرایش
          مجدد به‌صورت جزوه‌بندی‌شده با کاغذ اعلا، پشتی سخت و قطع-صحافت نفیس در 674
          صفحه از سوی این انتشارات تازه از ‌چاپ برآمده و قابل دریافت دوست‌داران بیدل
          است.
          <br/>
          <br/>
          این اثر که شامل سه فصل است، در فصل اول پیشینه‌های یونانی و هندی بعضاً
          اثرگذار بر روی‌کرد‌های شناخت‌شناختی (Epistemologie/Gnoseologie) و
          روشن‌شناختی (Methodologie) بیدل ارایه شده است. در فصل دوم شناخت‌شناختی بیدل
          یا نظریۀ شناخت/تیوری شناخت بیدل یا مهم‌ترین ابعاد شناخت‌شناختی متعارف در
          تاریخ فلسفه باز شده است. و در فصل سوم پس‌از اشاره بر مهم‌ترین روش‌های
          روش‌شناختی بیدل چون روش تمثیل (Analogik)، روش دیالکتیک (Dialektik)، روش
          خودسازی/اخلاق (Ethik) و روش نقد/سنجش (Kritik)، به‌مناسبت موضوعی مندرجات بخش
          پنجم انسان‌شناختی بیدل (شناخت‌شناختی و روش‌شناختی) از روش تمثیل (مدعا‌مثل) و
          روش دیالکتیک بیدل به‌تفصیل جامع و کافی سخن رفته است.
          <br/>
          <br/>
          باتوجه به‌این حقیقت که از یک‌سو حتا فشرده‌ای از محتوای سه‌ فصل اثر در این
          اطلاعیه گنجایش ندارد و از سوی دیگر علاقه‌مندان بیدل این معذرت فنی ما را با
          دل‌خوشی نمی‌پذیرند، در حل این مسئله مناسب دانستیم نخست فهرست این اثر را
          به‌هدف گزارشی از عناوین کلیات آن در این‌جا باز نماییم:
        </p>
        <h1 className={classes.paratitle}>فهرست مطالب</h1>
        <div align="center" className='classes.para'>
          <table className='classes.para' border="0" cellspacing="0" cellpadding="0">
              <tbody>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              عنوان موضوع
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                            صفحه
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              پیش‌گفتار
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1151
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              بخش چهارم
                          </p>
                      </td>
                      <td width="20%" valign="top">
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              شناخت‌شناختی و روش‌شناختی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1169
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              فصل اول- سیری بر مهم‌ترین پیشینه‌های شناخت‌شناختی و
                              روش‌شناختی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1172
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              الف- پیشینه‌های یونانی و یونانی‌مآبی شناخت‌شناختی و
                              روش‌شناختی بیدل
                          </p>
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              1- فیلسوفان یونانی (از تالس تا ارستو)
                          </p>
                      </td> 
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1175
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              2- یونانی‌مآبی (Hellenism) و مکاتب فلسفی آن
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1239
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              الف- کلبیان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1244
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              ب- رواقیان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1254
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              ج- اپیکوریان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1266
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                          
                              د- شکاکان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1277
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              ﻫ- گنوسیان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1284
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                          
                              و- نوافلاتونیان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1289
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                          
                              3- مدرسیان (Scholastikers)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1306
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                          
                              الف- مکتب واقع‌انگاری (Realism)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1312
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                          
                              ب- مکتب مفهوم‌‌انگاری (Conceptualism)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1317
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                          
                              ج- مکتب اسم‌انگاری (Nominalism)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1322
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                          
                              4- تجددگرایان دوران رنساس (Renaissance)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1331
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                          
                              5- دانش‌ورفیلسوفان عصر جدید
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1357
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}> 
                              ب- خاست‌گاه‌های هندی شناخت‌شناختی و روش‌شناختی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1368
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              1- مکتب چارواکه/ سارواکا (Carvaka)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1371
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              2و3- مکتب‌های وایسیشیکا (Veiseshika) ونیایا (Nyaya)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1375
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              4و5- مکتب‌های سانکهیه/ سامکایا (Samkhya) و جوگ/ یوگا
                              (Yoga)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1381
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              6 و7- مکتب‌های پوروامیمانسا (Purva- mimansa) و ودانتا
                              (Vedanta)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1387
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              8- مکتب بودیسم (Buddhism)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1397
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              9- مکتب جاینیسم (Jainism)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1405
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              فصل دوم- شناخت‌شناختی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1413
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              1- تحقیق، نه تقلید
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1420
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              2- طبیعت‌مایگی و دانش‌پایگی روح عرفانی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1431
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              3- خاست‌گاه و افزارهای شناخت
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1446
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              4- شناخت‌افزارهای حسی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1457
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              دماغ (مغز) یا دست‌گاه شناخت‌افزارهای پیدا و پنهان انسان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1458
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              1- شنوایی (سامعه)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1459
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              2- بینایی (باصره)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1462
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              3- بویایی (شامه)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1467
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              4- چشایی (ذایقه)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1470
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              5- بساوایی (لامسه)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1474
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              6- حس مشترک
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1476
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              7- خیال
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1479
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              8- متفکره (فکر)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1483
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              9- واهمه (وهم)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1487
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              10- حافظه (حفظ)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1491
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              5- عقل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1494
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              6- تأمل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1505
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              7- وحدت بُن‌مایه‌های خویش‌کاری افزار حسی و عقلی شناخت و
                              نتایج معرفتی آن‌ها
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1523
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{paddingRight: '5vw', fontFamily: 'vazir', fontWeight: '300'}} >
                              8- زبان
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1536
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              9- محدودیت شناخت
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1570
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              10- ندانم‌انگاری
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1579
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              11- نسبیت‌ شناخت
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1605
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              فصل سوم- روش‌شناختی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1635
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              1- روش تمثیل (Analogik)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1641
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              1- ارکان (اجزاء) تمثیل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1643
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              2- اقسام تمثیل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1646
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              الف- تمثیل غیر قطعی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1646
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '12vw'}}>
                              ب- تمثیل قطعی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1652
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              3- ارزش تمثیل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1654
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              4- نمونه‌هایی از روش تمثیل (مدعامثل) بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1655
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              2- روش دیالکتیک (Dialektik)
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1693
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              1- اصول اساسی دیالکتیک هراکلیتوس
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1694
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              2- افسانه‌‌مایه‌های دیالکتیک سقرات- افلاتون
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1702
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              3- خاست‌گاه و اصول دیالکتیک هگل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1707
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '5vw'}}>
                              4- روش دیالکتیکی بیدل
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1714
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              الف- اصل حرکت و دگرگونی عام طبیعت هستی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1716
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              ب- اصل تضاد
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1735
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              ج- اصل نفی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1777
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td width="60%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300', paddingRight: '8vw'}}>
                              د- برایندِ (مُنْتَجَّۀ) فرایندِ اصل تضاد و اصل نفی
                              دیالکتیکی
                          </p>
                      </td>
                      <td width="20%" valign="top">
                          <p style={{fontFamily: 'vazir', fontWeight: '300'}}>
                              1794
                          </p>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

        <p className={classes.paratitle}>
            و آن‌گاه در فرجام این اطلاعیه پیش‌آهنگ‌نامۀ پیش‌گفتار نویسندۀ اثر را باز
            می‌تابیم که از پای‌گاه شناخت‌شناختی و روش‌شناختی بیدل، در نقد و تردید
            خرافه‌پنداری و وهم‌انگاری‌های سنتی در همین‌ زمینه به‌نقل از خود بیدل
            می‌گوید:
        </p>
        <br/>
        
        <p className={classes.paratitle}>
            جهان از بد و نیک دارد بسی
            <br/>
            چه مـحرم شود تا نبیند کسی
        </p>
        <br/>
        <p className={classes.paratitle}>
            ز ”ساز کسان“، ”نغمه“ کردی بلند
            <br/>
            از این بـــــیش تـار ”تـــخیل“ مــــبند
        </p>
        <p className={classes.paratitle}>
            چه لافی به ”حرف کسان“ خامه‌وار
            <br/>
            صریری ز ”تحقیق“ خود هـم بــرآر
        </p>
        <br/>
        <p className={classes.paratitle}>
            برون‌ تاز از ”ظلمت وهم“ خویش
            <br/>
            بکن سیر در کوچۀ ”فهم“ خویش
        </p>
        <br/>
        <p className={classes.paratitle}>
            به”تحقیق“ کوش ای به‌غفلت مَثَل
            <br/>
            یـقین است ”علم یقین“ را ”عــمل“
        </p>
        <br/>
        <p className={classes.paratitle}>
            نخستین عمل صرف ”تحقیق“ کن
            <br/>
            دگــــر ذکـــر انــــکار و تــــــصدیق کن
        </p>
        <br/>
        <p className={classes.paratitle}>
            اگــر مرد تحقیقی ای ”وهم‌کار“
            <br/>
            تو هم نسخه‌ای از گریبان برآر
        </p>
        <br/>
        <p className={classes.paratitle}>
            که روشن شود تا چه داری ز خویش
            <br/>
            به”دعوا“ نرفته است ”تحقیق“ پیش
        </p>
        <p className={classes.paratitle}>
            متقاضیان محترم می‌توانند این اثر بکر و بی‌نظیر تحقیقی در رشتۀ بیدل‌شناختی را
            با تماس به انتشارات انسان فراچنگ آورند.
          <br/>
            دپلم انجینیر سید الیاس کاوش<br/>
            رئیس انتشارات انسان<br/>
            22 جولای 2023 میلادی / 31 سرطان 1402 خورشیدی
        </p>
      </div>
          <Grid className={classes.gridSpace} container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              {/* <p className={classes.para1}>
              علاقه‌مندان به‌هدف دریافت مجلد سوم انسان‌شناختی بیدل به‌گزینه‌های ذیل مراجعه بفرمایند.
              </p> */}
              <Box
                display="inline"
                justifyContent="center"
                alignItems="center"
                margin="4vh 0"
              >
                <BuyOptionMenu/>
              </Box>
            </Grid>
            <Grid style={{display: 'flex', justifyContent: 'center' }} item xs={12} sm={12} md={3} lg={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="4vh 0"
              >
                <FlipImage aks={oneside} text={"انسان شناختی بیدل مجلد سوم"}/>                
              </Box>
            </Grid>
            <Hidden mdDown>
            <Grid style={{display: 'flex', justifyContent: 'center' }} item xs={12} sm={12} md={3} lg={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="4vh 0"
              >
                <FlipImage aks={twoside} text={"Bidel's Anthropologie Vol. 3"}/>              
              </Box>
            </Grid>
            </Hidden>
          </Grid>
      </div>
    </div>
   );    
}