import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    para: {
        fontFamily: 'vazir',
        lineHeight: '2.4em',
        textAlign: 'justify',
        fontSize: '0.8em',
        fontWeight: 300,
        color: 'black'
      },
      cookieAlert: {
      "& .MuiAlert-icon": {
        fontSize: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black!important'
      }
    }
  });

export default function BasicAlerts() {
    const classes = useStyles();
  return (
    <Stack sx={{ width: '100%', "& .MuiAlert-filled":{
        backgroundColor: '#f0f8ff'
    } }} spacing={2}>
      <Alert variant="filled" severity="warning">
        <p className={classes.para}>
        <p>مقالۀ &rdquo;جلوه&zwnj;های جبر و اختیار در مثنوی مولوی&ldquo; اثر استاد دکتر سید نورالحق کاوش، که متن آن از سوی خود ایشان در اواخر سال 1999 در جمع سایر مقالات کنفرانس علمی، در تالار کنفرانس&zwnj;های علمی آکادمی علوم تاجیکستان قرائت گردیده و آن&zwnj;گاه در شمارۀ اول سال اول مجلۀ &rdquo;آمو&ldquo; (نشریۀ پژوهش&zwnj;گاه خاورشناسی آکادمی علوم تاجیکستان) در فبروری 2000 به&zwnj;چاپ نیز رسیده بود، اخیراً نشر مجدد آن از طریق ایمیل&zwnj;های مورخ 14 تا 25 جولای 2022 از سوی برخی اعضای آکادمی علوم تاجیکستان تقاضا گردید. از آن&zwnj;جا&zwnj; که از مجلد یکم &rdquo;مجموعۀ مقالات&ldquo; استاد کاوش که متن این مقاله درج صفحات 317-337 آن است، به&zwnj;آن تعدادی در اختیار نداریم که تقاضای همه علاقه&zwnj;مندان را برآورده سازد، ناگزیر بر اساس درخواست علاقه&zwnj;مندان، اینک متن کامل (با ویرایش مجدد) آن&zwnj;را به&zwnj;پیش&zwnj;گاه متقاضیان عزیز قرار می&zwnj;دهیم.</p>
<p>&nbsp;</p>
<p>خشنود باشید.</p>
<p>&nbsp;</p>
<p>سید الیاس کاوش</p>
<p>رئیس انتشارات انسان</p>
<br/>

        </p>
      </Alert>
    </Stack>
  );
}